export interface Iskill {
  name: string;
  type?: string;
}
interface ISkills {
  experienced: Iskill[];
  familiar: Iskill[];
}

export const skills: ISkills = {
  experienced: [
    { name: "html5" },
    { name: "css3" },
    { name: "javascript" },
    { name: "typescript" },
    { name: "mysql" },
    { name: "git" },
    { name: "react" },
    { name: "express", type: "original" },
  ],
  familiar: [
    { name: "redux", type: "original" },
    { name: "nextjs" },
    { name: "jest" },
    { name: "firebase" },
    { name: "mongodb" },
    { name: "python" },
    { name: "csharp" },
    { name: "kotlin" },
  ],
};

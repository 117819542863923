import React from "react";
import { Iskill } from "../data/skills";

interface Props {
  skills: Iskill[];
  express?: boolean;
}
export const SkillsGallery: React.FC<Props> = ({ skills, express }) => {
  return (
    <div className="skill-gallery">
      {skills.map((skill, idx) => (
        <div className="flex-column" key={idx}>
          <i
            className={`devicon-${skill.name}-${
              skill.type ?? "plain"
            } colored devicon`}
          ></i>
          <span>{skill.name}</span>
        </div>
      ))}
    </div>
  );
};

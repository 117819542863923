import React from "react";

interface Props {}
export const InactiveWarning: React.FC<Props> = () => {
  return (
    <p className="inactive-warning">
      The demo for this project is currently unavailable due to issues with the
      hosting provider.
    </p>
  );
};
